<template>
  <div>
    <v-card class="mx-auto">
      <v-app-bar dark color="primary">
        <v-icon large class="mr-3">assignment_turned_in</v-icon>
        <v-toolbar-title>Task Status</v-toolbar-title>
      </v-app-bar>
      <v-card-title>Assign Task to a User</v-card-title>
      <v-card-text>
        <v-select
          label="Select Activity"
          :items="tasks"
          item-text="name.en"
          item-value="_id"
          class="mt-3"
          v-model="dataToSend.taskId"
        />
        <v-text-field
          v-model="dataToSend.email"
          :rules="[(v) => !!v || 'Email is required']"
          label="Email"
          required
        ></v-text-field>
        <v-btn color="primary" class="mt-3" @click="assignTask"
          >Assign Task</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2";

const service = new RestResource();

export default {
  data() {
    return {
      tasks: [],
      dataToSend: {
        taskId: undefined,
        email: undefined,
      },
    };
  },

  mounted() {
    this.getTasks();
  },

  methods: {
    getTasks() {
      service.getTasks().then((r) => {
        this.tasks = r.data;
      });
    },

    assignTask() {
      this.$setLoader()
      service.assignTaskToUser(this.dataToSend).then((res) => {
        this.$showSuccessMessage(res.data)
      }).catch((e) => {
        console.log('eeee', e.response.data.message)
        this.$showSuccessMessage(e.response.data)
        this.$disableLoader()
      });
    },
  },
};
</script>
